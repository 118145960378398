import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
// eslint-disable-next-line import/no-commonjs
import COS from 'cos-js-sdk-v5'
import { useGetTokenMutation } from './verifySlice'

// 连接腾讯云COS
export default function useCosClient() {
  const [getToken] = useGetTokenMutation()
  const [ossClient, setClient] = useState<COS | null>(null)
  useEffect(() => {
    const client = new COS({
      getAuthorization: function (options: any, callback: any) {
        console.log('获取权限', options)
        getToken({
          bucket: options.Bucket,
          region: options.Region,
          uid: options.Bucket.split('-')[2],
        })
          .unwrap()
          .then((data: any) => {
            callback({
              TmpSecretId: data.AccessKeyId,
              TmpSecretKey: data.AccessKeySecret,
              SecurityToken: data.SecurityToken,
              ExpiredTime: dayjs(data.Expiration).unix(),
              StartTime: dayjs().unix(),
            })
          })
      },
    })
    setClient(client)
  }, [getToken])

  return ossClient
}
