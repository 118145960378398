import React, { useState, useEffect } from 'react'
import { Form, Input, Radio, Checkbox, Select, message, Steps } from 'antd'
import { useUpdateQaMutation, 
    QualificationQaItem,
    useCreateContractMutation,
    ContractRequest,
    useSendEmailCodeMutation,
    useSaveAddressInfoMutation,
    AddressInfo
} from './verifySlice'
import { qaList, COLLECTION_METHOD_OPTIONS, step3List, STEP_MAP } from './constant'
import { StyledStepTitle, 
    Step2Title, 
    MiniStepTitle, 
    MyButton, 
    StyledFormWrapper, 
    StyledMiniStepTitle,
    CodeButton
} from './style'
import UploaderImg from './uploaderImg'

export default function Content({ step, uid, username }: { step: number, uid: string, username: string }) {
  const [currentStep, setCurrentStep] = useState<number>(step)
  const [form] = Form.useForm()
  const [countdown, setCountdown] = useState(0)
  const [roomPhotoList, setRoomPhotoList] = useState<string[]>([])
  const [licenseImgList, setLicenseImgList] = useState<string[]>([])
  const [ updateQa ] = useUpdateQaMutation()
  const [ createContract ] = useCreateContractMutation()
  const [ sendEmailCode ] = useSendEmailCodeMutation()
  const [ saveAddressInfo ] = useSaveAddressInfoMutation()
  const [ qaAnswer, setQaAnswer ] = useState<QualificationQaItem[]>([])
  const [ step3Answer, setStep3Answer ] = useState<QualificationQaItem[]>([])
  const [ mailLoading, setMailLoading ] = useState(false)

  useEffect(() => {
    setCurrentStep(step)
  }, [step])

  const handleSendCode = () => {
    // 获取邮箱值
    const email = form.getFieldValue('email')
    if (!email) {
      message.error('请先填写邮箱')
      return
    }
    setMailLoading(true)
    sendEmailCode({
        email,
        usage: 2   // 参照小程序，写死为2表示修改账号信息
    }).then((res: any) => {
        setMailLoading(false)
        if (res && !res.error) {
            message.success('验证码已发送')
            setCountdown(60)
            const timer = setInterval(() => {
            setCountdown((prev) => {
            if (prev <= 1) {
            clearInterval(timer)
            return 0
            }
                return prev - 1
            })
            }, 1000)
        }
    })
  }

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      // 根据不同步骤调用不同接口
      if (currentStep === STEP_MAP.STEP1) {
        updateQa(qaAnswer).then((res: any) => {
          if (res && res.data && !res.error) {
            message.success(`提交成功`)
            setCurrentStep(prev => prev + 1)
            form.resetFields()
          }
        })
      }
      if (currentStep === STEP_MAP.STEP2) {
        if (licenseImgList?.length === 0) {
          message.error('请上传营业执照或其他身份证明')
          return
        }
        createContract({...values, photoUrls: licenseImgList} as ContractRequest).then((res: any) => {
          if (res && res.data && !res.error) {
            message.success('提交成功')
            setCurrentStep(prev => prev + 1)
            form.resetFields()
          } else {
            message.error(res?.error?.data?.message || '提交失败')
          }
        })
      }
      if (currentStep === STEP_MAP.STEP3) {
        // 删除values中以qa开头的字段
        Object.keys(values).forEach((key) => {
          if (key.startsWith('qa')) {
            delete values[key]
          }
        })
        saveAddressInfo({...values, qualificationRes: step3Answer, machineRoomPic: roomPhotoList[0] || '', account: username } as AddressInfo).then((res: any) => {
          if (res && res.data && !res.error) {
            message.success('提交成功')
          }
        })
      }
    } catch (error) {
      message.error('请填写所有必填信息')
    }
  }

  const renderStep1 = () => (
    <>
      <StyledStepTitle>资质初审</StyledStepTitle>
      {
        qaList.map((item, index) => (
          <Form.Item 
            key={index}
            label={item.q} 
            name={item.name} 
            rules={[{ required: true, message: '请选择' }]}
          >
            <Radio.Group 
              onChange={(e) => {
                const value = e.target.value
                if (!qaAnswer.find(qa => qa.topic === item.q)) {
                    setQaAnswer([...qaAnswer, { topic: item.q, answer: `${value}` }])
                } else {
                    setQaAnswer(qaAnswer.map(qa => qa.topic === item.q ? { ...qa, answer: `${value}` } : qa))
                }
              }}
            >
              {
                item.options.map((option, index) => (
                  <Radio key={index} value={option}>{option}</Radio>
                ))
              }
            </Radio.Group>
          </Form.Item>
        ))
      }
      <Form.Item 
        name="agreement" 
        valuePropName="checked" 
        rules={[{ required: true, message: '请勾选承诺' }]}
      >
        <Checkbox>我承诺以上所填信息真实有效</Checkbox>
      </Form.Item>
    </>
  )

  const renderStep2 = () => (
    <>
      <StyledStepTitle>合同信息</StyledStepTitle>
      <Step2Title>注意：联系人信息需要保证姓名、身份证、电话是同一人</Step2Title>
      <Form.Item
        label="上传营业执照或其他身份证明"
        name="photoUrls"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        extra="单个图片不超过3M，最多上传2张"
        required
      >
        <UploaderImg
          maxSize={3}
          maxCount={2}
          uid={uid}
          imgList={licenseImgList}
          setImgList={setLicenseImgList}
        />
      </Form.Item>
      <Form.Item 
        label="联系人姓名" 
        name="realName" 
        rules={[{ required: true, message: '请填写此字段' }]}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item 
        label="联系人身份证号" 
        name="IDCardNumber" 
        rules={[{ required: true, message: '请填写此字段' }]}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item 
        label="联系人手机号" 
        name="phoneNumber" 
        rules={[{ required: true, message: '请填写联系人手机号' }]}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item 
        label="邮箱" 
        name="email" 
        rules={[
          { required: true, message: '请填写邮箱' },
          { 
            pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            message: '请输入正确的邮箱格式'
          }
        ]}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Input placeholder="请输入邮箱" />
      </Form.Item>
      <Form.Item 
        label="公司全称" 
        name="bankAccount" 
        rules={[{ required: true, message: '请填写此字段' }]}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item 
        label="统一社会信用代码" 
        name="creditCode" 
        rules={[{ required: true, message: '请填写统一社会信用代码' }]}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Input placeholder="请输入统一社会信用代码" />
      </Form.Item>
      <Form.Item 
        label="注册地址" 
        name="address" 
        rules={[{ required: true, message: '请填写注册地址' }]}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Input placeholder="请输入注册地址" />
      </Form.Item>
      <Form.Item 
        label="资源国家|运营商" 
        name="ispLocation" 
        rules={[{ required: true, message: '请填写资源国家|运营商' }]}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Input placeholder="请输入资源国家|运营商" />
      </Form.Item>
      <StyledMiniStepTitle>收款信息</StyledMiniStepTitle>
      <Form.Item 
        label="收款方式" 
        name="collectionMethod" 
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Select placeholder="请选择收款方式">
          {
            COLLECTION_METHOD_OPTIONS.map((option) => (
              <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
            ))
          }
        </Select>
      </Form.Item>
      <Form.Item 
        label="收款账号" 
        name="collectionNumber" 
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Input placeholder="请输入收款账号" />
      </Form.Item>
      <Form.Item 
        label="邮箱验证码" 
        required
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <div style={{ display: 'flex' }}>
          <Form.Item
              name="verifyCode"
              rules={[{ required: true, message: '请输入验证码' }]}
              style={{ display: 'inline-block', width: 'calc(100% - 120px)', marginBottom: 0 }}
          >
              <Input placeholder="请输入验证码" />
          </Form.Item>
          <CodeButton
              type="primary"
              disabled={countdown > 0}
              onClick={handleSendCode}
              style={{ width: '120px', marginLeft: '8px' }}
              loading={mailLoading}
          >
              {countdown > 0 ? `${countdown}s后重试` : '发送验证码'}
          </CodeButton>
        </div>
      </Form.Item>
    </>
  )

  const renderStep3 = () => {
    return (
      <>
        <StyledStepTitle>节点信息收集</StyledStepTitle>
        <Step2Title>用户您好，为确保您能在我们平台正常提供服务，也为了我们能为您提供更标准的服务，请您完善以下信息，感谢您的配合！</Step2Title>
        <MiniStepTitle>您的节点/机房所在地址</MiniStepTitle>
        <Form.Item 
          label="国家地区" 
          name="location" 
          rules={[{ required: true, message: '请填写国家地区' }]}
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
        >
          <Input placeholder="请输入所在国家、地区" />
        </Form.Item>
        <Form.Item 
          label="联系地址" 
          name="address" 
          rules={[{ required: true, message: '请填写联系地址' }]}
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item 
          label="详细地址" 
          name="detailAddress" 
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
        >
          <Input placeholder="请输入详细地址" />
        </Form.Item>
        <Form.Item 
          label="楼层" 
          name="floor" 
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
        >
          <Input placeholder="请填写楼层" />
        </Form.Item>
        <Form.Item 
          label="房间号" 
          name="room" 
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
        >
          <Input placeholder="请填写房间号" />
        </Form.Item>
        <Form.Item 
          label="机柜号" 
          name="cabinet" 
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
        >
          <Input placeholder="请填写机柜号" />
        </Form.Item>
        <Form.Item 
          label="您在此地址的设备/节点id" 
          name="deviceUUIDs" 
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
        >
          <Input placeholder="请填写设备/节点id，多个用英文逗号隔开" />
        </Form.Item>
        <Form.Item
          label="机房照片"
          name="machineRoomPic"
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
          extra="单个文件不超过5M"
        >
          <UploaderImg
            imgList={roomPhotoList}
            setImgList={setRoomPhotoList}
            maxSize={3}
            maxCount={1}
            uid={uid}
            uploadPath="verify"
          />
        </Form.Item>
        {
          step3List.map((item, index) => (
            <Form.Item 
              key={index}
              label={item} 
              name={`qa${index + 1}`} 
              labelCol={{ span: 11 }}
              wrapperCol={{ span: 13 }}
            >
              <Radio.Group onChange={(e) => {
                const value = e.target.value
                if (!qaAnswer.find(qa => qa.topic === item)) {
                  setStep3Answer([...step3Answer, { topic: item, answer: `${value}` }])
                } else {
                  setStep3Answer(step3Answer.map(qa => qa.topic === item ? { ...qa, answer: `${value}` } : qa))
                }
              }}>
                <Radio value="是">是</Radio>
                <Radio value="否">否</Radio>
              </Radio.Group>
            </Form.Item>
          ))
        }
      </>
    )
  }

  return (
    <StyledFormWrapper>
      <Steps
        current={currentStep}
        items={[
          { title: '资质初审' },
          { title: '合同信息' },
          { title: '节点信息收集' },
        ]}
        style={{ marginBottom: '24px' }}
      />
      <Form 
        form={form} 
        layout={currentStep === STEP_MAP.STEP1 ? 'vertical' : 'horizontal'}
      >
        {currentStep === STEP_MAP.STEP1 && renderStep1()}
        {currentStep === STEP_MAP.STEP2 && renderStep2()}
        {currentStep === STEP_MAP.STEP3 && renderStep3()}
        <Form.Item 
          wrapperCol={
            currentStep === STEP_MAP.STEP1 
              ? { span: 24 } 
              : { offset: 6, span: 14 }
          }
        >
          <MyButton type="primary" onClick={handleSubmit}>
            {currentStep === STEP_MAP.STEP3 ? '提交' : '提交并下一步'}
          </MyButton>
        </Form.Item>
      </Form>
    </StyledFormWrapper>
  )
}