import React from 'react'
import styled from 'styled-components'
import { isPainet } from '../utils/distinguishDomain'

const FooterWrapper = styled.footer`
  padding: 48px;
  background-color: #0d0f30;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
`
export default function Footer() {
  return (
    <FooterWrapper>
      <div>© {new Date().getFullYear()} 深圳启派云智科技有限公司 版权所有</div>
      <a
        href="https://beian.miit.gov.cn/"
        target={'_blank'}
        rel="noopener noreferrer"
        style={{ color: '#fff' }}
      >
        粤ICP备2024308261号-4
      </a>
    </FooterWrapper>
  )
}
