import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react'
import Axios, { AxiosRequestConfig, AxiosError } from 'axios'
import { apiBaseurl, adminApiBaseurl, painetApiurl, kvAPiBaseurl } from '@/config'
import { AxiosInitialInstance } from '@/utils/request'
import { message } from 'antd'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<AxiosRequestConfig, unknown, unknown> =>
  async ({ url, ...rest }) => {
    try {
      const result = await AxiosInitialInstance({
        url: baseUrl + url,
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        ...rest,
      })
      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: {
            ...(err.response?.data || {}),
            url: err.response?.config?.url,
          },
        },
      }
    }
  }

export const apiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: apiBaseurl,
  }),
  endpoints() {
    return {}
  },
  tagTypes: ['InvoiceList', 'BillList', 'DeviceList', 'DialList', 'AccountInfo'],
  reducerPath: 'commonApiSlice',
})
export default apiSlice

//供应商网关处理后，这两个slice可以合并
export const adminApiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: adminApiBaseurl,
  }),
  endpoints() {
    return {}
  },
  tagTypes: [
    'Wallet',
    'BusinessIdList',
    'FilterDeployableMachine',
    'BusinessSwitchOrderHistory',
  ],
  reducerPath: 'adminApiSlice',
})

//下面的要复用小程序的逻辑，所以域名不一样
export const painetApiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: painetApiurl,
  }),
  endpoints() {
    return {}
  },
  tagTypes: [],
  reducerPath: 'painetApiSlice',
})

//kv-store
export const axiosKvGet = (path: string, params?: any) => {
  // return Axios(kvAPiBaseurl + path, {
  //   params: params || {},
  //   withCredentials: false,  // 跨域请求需要设置
  // })
  //   .then((res) => res.data)
  //   .catch(() => {
  //     message.error('配置信息获取有误')
  //   })
  // 不适用axios，因为会默认带上token，导致跨域请求失败
  const queryString = params ? '?' + new URLSearchParams(params).toString() : '';
  
  return fetch(`${kvAPiBaseurl}${path}${queryString}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'omit', // 明确不发送凭证
    headers: {
      'Accept': 'application/json'
    }
  })
    .then(res => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .catch(() => {
      message.error('配置信息获取有误');
    });
}