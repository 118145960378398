import React, { useMemo } from "react";
import type { DatePicker } from "antd";
import moment, { Moment } from "moment";
import styled from "styled-components";
const Wrap = styled.div`
  span {
    margin-right: 10px;
    cursor: pointer;
  }
  .on {
    color: var(--primary-color);
  }
`;
export const RANGE_OPTIONS = {
  get THIS_MONTH() {
    return {
      label: "本月",
      range: [moment().startOf("month"), moment().endOf("month")],
    };
  },
  get LAST_MONTH() {
    return {
      range: [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
      label: "上月",
    };
  },
  get YESTODAY() {
    return {
      range: [
        moment().subtract(1, "day").startOf("day"),
        moment().subtract(1, "day").endOf("day"),
      ],
      label: "昨天",
    };
  },
  get LAST_SEVEN_DAYS() {
    return {
      range: [
        moment().subtract(6, "day").startOf("day"),
        moment().endOf("day"),
      ],
      label: "近七天",
    };
  },
  get LAST_SEVEN_DAYS_WITHOUT_TODAY() {
    return {
      range: [
        moment().subtract(7, "day").startOf("day"),
        moment().subtract(1, "day").endOf("day"),
      ],
      label: "近七天",
    };
  },
  get THIS_YEAR() {
    return {
      range: [moment().startOf("year"), moment().endOf("year")],
      label: "今年",
    };
  },
};
export type BtnKind = keyof typeof RANGE_OPTIONS;

export interface PropsValue extends Array<Moment> {
  isMaual?: BtnKind;
}

interface Props {
  /**
   * @description       当前控件的默认值
   * @default
   */
  value: PropsValue;
  /**
   * @description       显示的按钮数组
   * @default
   */
  btnArr: BtnKind[];
  /**
   * @description       子组件时间变化调用的父组件传入的事件
   * @default
   */
  setValue: (p: PropsValue) => void;
  /**
   * @description       将antd的RangePicker传进来，避免重复配置主题等
   * @default
   */
  RangePicker: typeof DatePicker.RangePicker;
  [propsName: string]: any;
}

//根据父组件传的value值对当前组件state进行初始化和修改
//如果使用Moment数组匹配到多个则高亮当前展示的第一个
const getDateValue = (targetValue: PropsValue) => {
  function targetValueToBtn(value: PropsValue) {
    return (
      Object.entries(RANGE_OPTIONS).find((item) =>
        item[1].range.every((time, index) => {
          return time.isSame(value[index], "day");
        })
      )?.[0] ?? ""
    );
  }

  return {
    range: targetValue.isMaual
      ? RANGE_OPTIONS[targetValue.isMaual].range
      : targetValue,
    selectedBtn: targetValue.isMaual
      ? targetValue.isMaual
      : targetValueToBtn(targetValue),
  };
};

const Ranger = ({ value, btnArr, setValue, RangePicker, ...others }: Props) => {
  //value是当前界面展示的数据源，但按钮高亮情况根据是否是点击触发有所区别，如果是点击就高亮对应的一个，否则就高亮匹配到的第一个
  //给value添加属性isManual，表示是否是点击触发的
  const date = useMemo(() => getDateValue(value), [value]);

  return (
    <Wrap>
      {btnArr.map((item) => {
        return (
          <span
            className={date.selectedBtn === item ? "on" : ""}
            onClick={() => {
              const v: PropsValue = RANGE_OPTIONS[item].range;
              v.isMaual = item;
              setValue(v);
            }}
            key={item}
          >
            {RANGE_OPTIONS[item].label}
          </span>
        );
      })}

      <RangePicker
        style={{ marginLeft: "15px" }}
        allowClear={false}
        onChange={(dates: any) => {
          const start = dates[0] as Moment;
          const end = dates[1].endOf("day") as Moment;
          setValue([start, end]);
        }}
        {...others}
        value={date.range as [Moment, Moment]}
      />
    </Wrap>
  );
};

export default Ranger;
