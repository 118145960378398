import { apiSlice } from '@/api/apiSlice'

export interface QualificationQaItem {
    topic: string
    answer: string
}
interface UserInfo {
    realName: string
    IDCardNumber: string
    phoneNumber: string
    verifiedStep: number //0 | 1 | 2 | 3
    qualificationRes: QualificationQaItem[]
    contractStatus: '' | 'underSign' | 'signed' //未提交申请,审核中,已签署
    needVerify: boolean //是否需要补充信息
    NeedFillCollection: boolean //是否需要填写节点信息
   // paymentMethod: collectionMethodType //付款方式
    hasDistribution: boolean //是否有分销权限
    uid: string,
    username: string,
  }
  export interface VerifyImgProps {
    img: FormData
  }
  export interface ContractRequest {
    realName?: string
    phoneNumber?: string
    IDCardNumber?: string
    collectionMethod?: string
    verifyCode?: string
    address?: string
    email?: string
    collectionNumber?: string
    ispLocation?: string
    bankAccount?: string
    photoUrls?: string[]
  }
  export interface AddressInfo {
    location: string
    address?: string
    detailAddress: string
    floor?: string | number
    room: string
    cabinet: string
    deviceUUIDs: string
    machineRoomPic: string
    qualificationRes: { topic: string; answer: string }[]
    [k: string]: string | { topic: string; answer: string }[] | number | undefined
  }
  interface TokenRes {
    AccessKeyId: string
    AccessKeySecret: string
    SecurityToken: string
    Expiration: string
  }
const verifySlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserInfo: build.query<UserInfo, void>({
        query: () => {
          return {
            url: '/account/v1/info',
          }
        },
        transformResponse: (response: UserInfo) => {
          return { ...response }
        },
        providesTags: ['AccountInfo'],
      }),
    updateQa: build.mutation<void, QualificationQaItem[]>({
        query: (data) => {
          return {
            url: '/account/v1/saveQualificationQs',
            data: { qualificationRes: data },
            method: 'POST',
          }
        },
        invalidatesTags: ['AccountInfo'],
      }),
    createContract: build.mutation<void, ContractRequest>({
      query: (data) => {
        return {
          url: '/v1/user/authenticate',
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['AccountInfo'],
    }),
    sendEmailCode: build.mutation<void, {email: string, usage: number}>({
      query: ({email, usage}) => {
        return {
          url: '/v1/user/send_verify_code',
          data: { email, usage },
          method: 'POST',
        }
      },
    }),
    saveAddressInfo: build.mutation<void, AddressInfo>({
      query: (data) => {
        return {
          url: '/account/v1/saveAddressInfo',
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['AccountInfo'],
    }),
    getToken: build.mutation<TokenRes, { bucket: string; region: string; uid: string }>({
      query: (data) => {
        // 这个参数其实后端没有接受，后端是写死的前端告诉的那个存储桶的名称和区域
        // 目前是pi-static-1329507684 和 ap-singapore
        return {
          url: `/help/v1/sts?bucket=${data.bucket}&region=${data.region}&uid=${data.uid}`,
        }
      },
    }),
  }),
})
export const { 
  useUpdateQaMutation, 
  useGetUserInfoQuery, 
  useCreateContractMutation,
  useSendEmailCodeMutation,
  useSaveAddressInfoMutation,
  useGetTokenMutation
} 
  = verifySlice
