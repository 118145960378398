export const qaList = [
    {
        q: '您有从事过/正在从事以下业务吗？(如动态vps/拨号vps/adsl拨号服务器/代理Ip/动态Ip)',
        options: ['做过', '正在做', '没有'],
        name: 'q1'
      },
      {
        q: '带宽是由您直接通过运营商开出吗？',
        options: ['是', '否'],
        name: 'q2'
      },
      {
        q: '您办理带宽的地址和使用地址是否一致？',
        options: ['是', '否'],
        name: 'q3'
      },
]
export const COLLECTION_METHOD_OPTIONS = [
    { label: '银行卡', value: 'enterpriseCard' },
    { label: 'PayPal', value: 'personalPayPal' },
  ]

  export const step3List = [
    '您的机房是否有空调等降温设施？',
    '您的机房是否有防火墙？',
    '您的机房是否有消防灭火系统？',
    '您的机房是否有24小时值班人员？',
  ]

  export const cosCfg = {
    Bucket: 'pi-static-1329507684' /* 存储桶 */,
    Region: 'ap-singapore' /* 存储桶所在地域 */,
    StorageClass: 'STANDARD'
  } 

  export const STEP_MAP = {
    STEP1: 0,
    STEP2: 1,
    STEP3: 2,
  }