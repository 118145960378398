import styled from 'styled-components'
import { Button } from 'antd'

export const StyledFormWrapper = styled.div`
  max-width: 1100px;
  min-width: 1000px;
  margin: 0 auto;
  padding: 24px;
  background: #fff;
  min-height: 700px;
`

export const StyledStepTitle = styled.h2`
  margin-bottom: 24px;
  color: #333;
  font-size: 18px;
  font-weight: 600;
`
export const StyledMiniStepTitle = styled.h3`
  margin-bottom: 10px;
  color: #333;
  font-size: 18px;
  font-weight: 600;
`
export const Step2Title = styled.div`
  margin-bottom: 10px;
  color: #666;
  font-size: 14px;
`
export const MiniStepTitle = styled.div`
  margin-bottom: 10px;
  color: #333;
  font-size: 14px;
  margin-left: 40px;
font-weight: 600;
`

export const MyButton = styled(Button)`
  line-height: 24px;
`
export const CodeButton = styled(Button)`
  line-height: 26px;
`