import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Footer.module.css'
import { Space } from 'antd'
import querystring from 'querystring'
import PyFooter from '../../pyinfra/components/Footer'
import { isPainet } from '../../pyinfra/utils/distinguishDomain'
export const getPageParam = () => {
  // 解析页面链接参数，看有没有from参数，如果有并且值为operations，则是运营线下推广抖音来源的注册页
  // 需要将注册来源设置为抖音
  // 在这里也解析的目的是为了让用户在登录进去之前的跳转路径也带上from参数，这样跳转回注册页面时还能带参数
  const url = window.location.href
  const queryStrings = url.split('?')[1]
  let from: string = ''
  if (queryStrings) {
    const queryObj = querystring.parse(queryStrings)
    from = queryObj.from as string
  }
  return from
}
const Footer: React.FC<{}> = () => {
  const from = getPageParam()
  if (!isPainet()) {
    return <PyFooter />
  }
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <p className={styles.link}>
          <Space className={styles.space}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019337"
            >
              <img
                src="/gongan.png"
                alt=""
                style={{ height: '1em', marginRight: '5px' }}
              />
              沪公网安备31011502019337号
            </a>
            <a
              href="https://beian.miit.gov.cn/"
              target={'_blank'}
              rel="noopener noreferrer"
            >
              沪ICP备2022027420号-2
            </a>
            <text>增值电信业务经营许可证：B1-20201534 沪B2-20200549</text>
            <a href="/agreement.html">用户协议</a>
            <Link to={`/help-center${from ? `?from=${from}` : ''}`}>
              帮助中心
            </Link>
          </Space>
        </p>
        <br />
        <p className={styles.copyright}>
          Copyright © {new Date().getFullYear()} www.painet.work All Rights
          Reserved Pedge平台
        </p>
      </div>
    </footer>
  )
}

export default Footer
