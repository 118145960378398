import React from 'react'
import styled from 'styled-components'
const WarnSpan = styled.span`
  color: ${(props) => props.theme.errorColor};
`
export default function Desc() {
  return (
    <div
      style={{
        backgroundColor: '#EAEFFF',
        padding: '20px 24px',
        color: 'rgba(0,0,0,0.85)',
        marginTop: '20px',
      }}
    >
      <WeeklyText></WeeklyText>
    </div>
  )
}

export function WeeklyText() {
  return (
    <>
      <div style={{ fontWeight: 500 }}>周结规则说明：</div>
      1. 每周收益会在次周三放入周结钱包，每周三申请提现，周四打款；
      <br /> 2. 每<WarnSpan>周三</WarnSpan>可提现<WarnSpan>1次</WarnSpan>，提现额度¥1.00~30000.00美元；
      <br /> 3. 非95计费的设备、平台托管设备<WarnSpan>不</WarnSpan>支持周结，请到月结账号中提现；
      <br /> 4. 周结会在6%税费基础上，自动扣除对应手续费（根据实际情况调整）。
    </>
  )
}
