import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
const Modal = styled.div`
  height: 600px;
  background-color: #fff;
  text-align: center;
  margin-top: 30px;
  padding-top: 80px;
  width: 100%;
  font-size: 20px;
`
interface BillTipProps {
  content?: string
}
const BillTip: React.FC<BillTipProps> = ({ content }) => {
  return (
    <Modal>
      <div>
       {content || <div>您尚未完成实名认证，如需查看更多，请先返回【用户中心】完成<Link to="/user/certification">实名认证</Link>！</div>}
      </div>
    </Modal>
  )
}

export default BillTip
