import React, { useState } from 'react'
import { Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import type { UploadFile, UploadProps } from 'antd/es/upload/interface'
import mime from 'mime-types'
import useCosClient from './useCosClient'

interface UploaderImgProps {
  maxSize?: number
  maxCount?: number
  uid?: string
  uploadPath?: string
  imgList: string[]
  setImgList: (licenseImgList: string[]) => void
}

export default function UploaderImg({
  maxSize = 3,
  maxCount = 1,
  uid = '',
  uploadPath = 'verify',
  imgList,
  setImgList
}: UploaderImgProps) {
  const client = useCosClient()
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const isLtMaxSize = file.size / 1024 / 1024 < maxSize
      if (!isLtMaxSize) {
        message.error(`图片大小不能超过 ${maxSize}MB!`)
        return false
      }
      return true
    },
    disabled: loading,
    onChange: ({ fileList: newFileList, file }) => {
      if (file.status === 'removed') {
        const removedUrl = file.response || file.url
        setImgList(imgList.filter(url => url !== removedUrl))
        setFileList(newFileList)
      }
      if (imgList?.length < maxCount) {
        setFileList(newFileList)
      }
    },
    customRequest: async ({ onSuccess, onError, file }) => {
      if (imgList.length >= maxCount) {
        message.error(`最多上传${maxCount}张图片`)
        return
      }
      setLoading(true)
      const fileName =
        `${uploadPath}/` + uid + '-' + Date.now() + '.' + mime.extension((file as File).type || 'image/png')
      client?.putObject(
        {
          Bucket: 'platform-statics-1329507684',
          Region: 'ap-singapore',  

          StorageClass: 'STANDARD',
          Key: fileName,
          Body: file,
        },
        function (err: any, data: any) {
          if (err) {
            onError?.(err)
          } else {
            const url = 'https://statics.pedge.cloud/' + fileName
            onSuccess?.(url)
            setImgList( [...imgList, url])
          }
          setLoading(false)
          console.log(err || data)
        },
      )
    },
    fileList,
    maxCount,
    accept: 'image/*',
    listType: "picture"
  }

  return (
    <Upload {...uploadProps}>
      <Button icon={<UploadOutlined />}>点击上传</Button>
    </Upload>
  )
}
