import React, { useMemo, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams, useLocation } from 'react-router-dom'
import Doc1 from './doc-ip.md'
import Doc2 from './doc-server.md'
import Doc3 from './doc-soft-mto1.md'
import Doc4 from './doc-soft-1to1.md'
import Doc5 from './doc-console.md'
import Doc6 from './doc-bind-device.md'
import Doc7 from './doc-x86.md'
import Doc8 from './doc-network.md'
import 'github-markdown-css'
import './HelpDoc.css'
import Nav from '../../welcome/Nav'
import Footer from '../../welcome/Footer'
import { getRecommendImagePackage } from '@/utils/api'
import { useAsync } from '@umijs/hooks'

interface Props {
  docType?: string
}
const HelpDoc: React.FC<Props> = ({ docType }) => {
  let { docname } = useParams<{ docname: string }>()

  const { pathname } = useLocation()

  const { data: recommendImagePackage } = useAsync(getRecommendImagePackage)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const { src, title } = useMemo(() => {
    if (docType) {
      docname = docType
    }
    switch (docname) {
      case 'doc-ip':
        return { src: Doc1, title: '【教程】城域网节点设置IP' }
      case 'doc-server':
        return { src: Doc2, title: '【教程】怎么接入网节点' }
      case 'doc-soft-mto1':
        return { src: Doc3, title: '派享云带宽接入网节点（多对一方案）' }
      case 'doc-soft-1to1':
        return { src: Doc4, title: '派享云带宽接入网节点（一对一方案）' }
      case 'doc-console':
        return { src: Doc5, title: '【教程】用户后台功能' }
      case 'doc-bind-device':
        return { src: Doc6, title: '【教程】绑定设备到后台' }
      case 'doc-network':
        return { src: Doc8, title: '【教程】配置网络' }
      case 'doc-x86':
        let Doc7Replaced = String(Doc7)
        Doc7Replaced = Doc7Replaced.replace(
          /%https_image_package_url%/g,
          recommendImagePackage?.downloadURL ??
            'https://infra-iaas.s3.ap-southeast-1.amazonaws.com/iso/pai-network/Hw-debian-firmware-1.0.4.iso',
        )
        Doc7Replaced = Doc7Replaced.replace(
          /%https_image_package_md5%/g,
          recommendImagePackage?.md5 ?? '4cbc41475cd944889a1ce4f2b0121067',
        )
        return { src: Doc7Replaced, title: '【教程】X86上机（带链接）' }
      default:
        return { src: '', title: '403 Not Found' }
    }
  }, [docname, recommendImagePackage])

  return (
    <>
      {!docType && <Nav />}
      <h1 className="markdown-title-wrapper">
        <div className="markdown-title">{title}</div>
      </h1>
      <div className="markdown-body">
        <ReactMarkdown source={src} />
      </div>
      {!docType && <Footer />}
    </>
  )
}

export default HelpDoc
