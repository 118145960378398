import React from 'react'

export default function PyinfraLogo() {
  return (
    <span
      style={{
        color: '#fff',
        fontSize: '30px',
        // fontWeight: 'bold',
      }}
    >
      启派云智
    </span>
  )
}
