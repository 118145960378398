type domainType = 'pyinfra' | 'pedge'

export default function distinguishDomain(): domainType {
  const hostname = window.location.hostname
  if (hostname.includes('pyinfra')) {
    return 'pyinfra'
  }
  return 'pedge'
}

export function isPainet() {
  return distinguishDomain() === 'pedge'
}
