import { createSlice } from '@reduxjs/toolkit'
import { axiosKvGet } from '@/api/apiSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
interface CountryItem {
  name: string
  region: string[]
  isp: string[]
}

const initialState: CountryItem[] = []
const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    updateCountryList(state, action) {
      return action.payload
    },
  },
})
export const { updateCountryList } = countrySlice.actions
export default countrySlice.reducer

export function useFetchCountryList() {
  const dispatch = useDispatch()
  useEffect(() => {
    axiosKvGet('/country-isp-region-lists').then((res) => {
      dispatch(
        updateCountryList(
          res.map((i: any) => {
            return {
              name: i.country,
              region: i.region?.map((r: any) => r.name) ?? [],
              isp: i.isp?.map((j: any) => j.name) ?? [],
            }
          })
        )
      )
    })
  }, [dispatch])
}
