import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import React, { lazy, Suspense, useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import store from '../redux'
import { login, logout } from '../redux/user/actions'
import Authorized from './Authorized'
import { Route } from 'react-router-dom'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import {
  $SUCCESS_COLOR,
  $ERROR_COLOR,
  $Link_COLOR,
  $WARN_COLOR,
} from '@/constants/styles'
import { useFetchCountryList } from '@/redux/country/reducers'

const Auth = lazy(() => import('../views/account/auth'))
const LoginRecommend = lazy(() => import('../views/welcome/loginRec'))
const VipRecruit = lazy(() => import('../views/welcome/VipRecruit'))
const MobileBind = lazy(() => import('../views/mobile/Bind'))
const Register = lazy(() => import('../views/account/register/Register'))
const HelpCenter = lazy(() => import('../views/help-center'))
const ResetPassword = lazy(
  () => import('../views/account/reset-password/ResetPassword'),
)
const HelpDoc = lazy(() => import('../views/bind-device/docs/HelpDoc'))
const NodeAddress = lazy(() => import('../views/external-form/node-address'))
// const TicketMobile = lazy(() => import('../views/tickets/mobile'))
// const TicketMobileList = lazy(() => import('../views/tickets/mobile-list'))

// 时间日期格式化
moment.locale('zh-cn')

export { store }

const GlobalStyle = createGlobalStyle`
  #root .nc-container .nc_scale span {
    height: 100%;
  }
`

const App = () => {
  const dispatch = useDispatch()
  // 请求国家列表
 useFetchCountryList()
  useEffect(() => {
    const token = localStorage.getItem('token')
    token ? dispatch(login(token)) : dispatch(logout())
  }, [dispatch])

  return (
    <ConfigProvider locale={zhCN}>
      <GlobalStyle />
      <Router>
        <Suspense fallback={null}>
          <Switch>
            {/* <Route path="/login" exact>
              <Login />
            </Route> */}
            <Route path="/auth" exact>
              <Auth />
            </Route>
            <Route path="/m/bind" exact>
              <MobileBind />
            </Route>
            <Route path="/register" exact>
              <Register />
            </Route>
            <Route path="/help-center">
              <HelpCenter />
            </Route>
            <Route path="/help/doc/:docname" exact>
              <HelpDoc />
            </Route>
            <Route path="/reset-password" exact>
              <ResetPassword />
            </Route>
            <Route path="/login-rec" exact>
              <LoginRecommend />
            </Route>
            <Route path="/vip-recruit" exact>
              <VipRecruit />
            </Route>
            <Route path="/external-form/node-address" exact>
              <NodeAddress></NodeAddress>
            </Route>
            <Authorized />
          </Switch>
        </Suspense>
      </Router>
    </ConfigProvider>
  )
}

type themeColor = 'success' | 'error' | 'link' | 'warn'
export type ThemeField = {
  [key in themeColor as `${key}Color`]: string
}
const theme: ThemeField = {
  successColor: $SUCCESS_COLOR,
  errorColor: $ERROR_COLOR,
  linkColor: $Link_COLOR,
  warnColor: $WARN_COLOR,
}
const ConnectReduxApp = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
)

const isNodeProd = process.env.NODE_ENV === 'production'

export default ConnectReduxApp
// export const __bl = BrowserLogger.singleton({
//   pid: 'ikswpaln79@559b0c260a59e18',
//   appType: 'web',
//   imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
//   sendResource: true,
//   enableLinkTrace: true,
//   behavior: isNodeProd, // 开发环境自己可以还原场景，behavior劫持了console，干扰了console的堆栈
//   useFmp: true,
//   enableSPA: true,
//   enableConsole: true,
//   setUsername: function () {
//     return store?.getState()?.user?.username || null
//   },
//   environment:
//     process.env.REACT_APP_API_ENV === 'production'
//       ? 'prod'
//       : isNodeProd
//       ? 'daily'
//       : 'local',
// })
