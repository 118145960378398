import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CommonModal from './components/CommonModal'
import NodeInfoCollectionModal from './components/NodeInfoCollectionModal'
import Monthly from './components/monthly'
import useGlobalModal from '@/route/globalModalContext'
import BillTip from './components/BillTip'
import {
  currencyTransfer,
  useGetBalanceQuery,
  initialBalance,
} from './walletSlice'
import { useAppSelector } from '@/redux'
import { useGetUserInfoQuery } from '@/views/pcenter/certification/verifySlice'
export const Title = styled.div`
  display: flex;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 15px;
  .desc {
    font-size: 22px;
    color: #414141;
    line-height: 1;
  }
`

export const currenceFormat = (price: number) => currencyTransfer(price, 0)
export type ModalType =
  | 'requiredPassword' //没设置钱包密码
  | 'inputPassword' //输入钱包密码
  | 'requiredIdentify' //未实名认证
  | 'requiredCollectionInfo' //未确认收款信息
  | 'withdrawaling' //提现中
  | 'requireFillNodeInfo' //需要填写节点信息
  | '' //正常

export type SetModal = React.Dispatch<React.SetStateAction<ModalType>>
export default () => {
  const {
    walletPasswordFilled,
    payInfo,
    collectionInfoFilled,
    needFillCollection,
  } = useAppSelector((state) => state.user)
  const { data: balance = initialBalance } = useGetBalanceQuery(1)
  const { ScanToMiniappModal, noticeStatus: globalModalShow } = useGlobalModal()
  const [walletType, switchType] = useState(1)
  const [modalStatus, setModal] = useState<ModalType>('')
  const { data: userInfo } = useGetUserInfoQuery()
  //显示周结选项的条件，结算周期参数为周结，或余额>0
  const showWeeklySettlement =
    payInfo.billingCycle === 'week' || balance.leftAmount > 0

  const [walletTypeOptions, setWalletTypeOptions] = useState<
    Array<{ label: string; value: number }>
  >([])

  useEffect(() => {
    setModal(getModalStatus())
    function getModalStatus() {
      //初始的modal逻辑
      //如果存在全局弹窗，就不会在当前页面显示弹窗
      if (globalModalShow) {
        return ''
      }

      //如果没有补充节点信息，且已补充完收款信息
      if (collectionInfoFilled && needFillCollection) {
        return 'requireFillNodeInfo'
      }

      return  ''
    }
  }, [needFillCollection, walletPasswordFilled, globalModalShow])
  useEffect(() => {
    if (showWeeklySettlement) {
      setWalletTypeOptions([
        { label: '周结', value: 0 },
        { label: '月结', value: 1 },
      ])
      switchType(0)
    } else {
      setWalletTypeOptions([])
      switchType(1)
    }
  }, [showWeeklySettlement])

  if (ScanToMiniappModal) {
    return <>{ScanToMiniappModal}</>
  }
  // 这个逻辑其实进不去，因为是必须要先进行了实名认证才能查看账单结算，而实名认证中又必须要填写节点信息
  if (modalStatus === 'requireFillNodeInfo') {
    return <NodeInfoCollectionModal></NodeInfoCollectionModal>
  }
  if (!userInfo?.needVerify &&  userInfo?.contractStatus !== 'signed') {
    return <BillTip content="您尚未完成合同签署，请联系商务签署合同！联系电话 +8615360890929"/>
  }

  return (
    <div>
      <Monthly setModal={setModal} modalStatus={modalStatus}></Monthly>
      {(modalStatus === 'requiredIdentify' ||
        modalStatus === 'requiredCollectionInfo') && (
        <CommonModal
          type={modalStatus}
          onCancel={(value: ModalType) => setModal(value)}
        ></CommonModal>
      )}
    </div>
  )
}
