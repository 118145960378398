import React from 'react'
import { SetModal, ModalType } from '../..'

import RecordItem from '../RecordItem'
import Billing from './Billing'
export default function Monthly({
  setModal,
  modalStatus,
}: {
  setModal: SetModal
  modalStatus: ModalType
}) {
  return (
    <div>
      <Billing />
      <RecordItem type="monthly-entry"></RecordItem>
    </div>
  )
}
