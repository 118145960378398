import React, { useState, useEffect } from 'react'
import { Radio } from 'antd'
import Login from './components/login'
import Wallet from './components/wallet'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Card from '../common/Card'

const DivTitle = styled.div`
  font-size: 22px;
  line-height: 22px;
  margin-top: 40px;
  margin-bottom: 18px;
`
export default () => {
  const [curtab, setCur] = useState('login')
  let { search } = useLocation()

  const { needReconcileUser, phoneNumber } = useSelector(
    (state: any) => state.user,
  )
  useEffect(() => {
    let searchParams = new URLSearchParams(search)
    if (needReconcileUser && searchParams.get('type') === 'wallet') {
      setCur('wallet')
    }
  }, [needReconcileUser, search])
  return (
    <>
      {/* <Radio.Group
        style={{ marginTop: '10px' }}
        defaultValue="income"
        onChange={(e) => {
          setCur(e.target.value)
        }}
        value={curtab}
      >
        <Radio.Button value="login">修改登录密码</Radio.Button>
        {needReconcileUser && (
          <Radio.Button value="wallet">设置钱包密码</Radio.Button>
        )}
      </Radio.Group> */}
      <DivTitle>修改登录密码</DivTitle>
      <Card>
        {curtab === 'login' ? <Login /> : <Wallet phoneNumber={phoneNumber} />}
      </Card>
    </>
  )
}
