import React, { useState } from 'react'
import styles from './Nav.module.css'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button } from 'antd'
import NoticeModal from '@/components/NoticeModal'
import Xiaozhouchao from '@/assets/images/xiaozhaochao.jpg'
import { getPageParam } from './Footer'
import { isPainet } from '@/pyinfra/utils/distinguishDomain'
import PyinfraLogo from '@/pyinfra/components/Logo'
const Nav: React.FC<{}> = () => {
  const history = useHistory()
  const location = useLocation()
  const [toggle, setToggle] = useState(false)
  const [registerModalVisible, setRegister] = useState(false)

  const from = getPageParam()
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <nav className={styles.nav}>
          {isPainet() ? (
            <img
              className={styles.nav_logo}
              src={require('../../assets/welcome/logo@2x.png')}
              alt="logo"
            />
          ) : (
            <PyinfraLogo></PyinfraLogo>
          )}

          <div className={styles.nav_right}>
            <ul className={styles.nav_list}>
              {/* <li>
                <Link
                  className={styles.nav_link}
                  style={{
                    color:
                      location.pathname.indexOf('welcome') !== -1
                        ? '#2e5bff'
                        : '#ffffff',
                  }}
                  to={`/welcome${from ? `?from=${from}` : ''}`}
                >
                  首页
                </Link>
              </li>
              <li>
                <Link
                  className={styles.nav_link}
                  style={{
                    color:
                      location.pathname.indexOf('vip-recruit') !== -1
                        ? '#2e5bff'
                        : '#ffffff',
                  }}
                  to={`/vip-recruit${from ? `?from=${from}` : ''}`}
                >
                  大客户通道
                </Link>
              </li>
              <li>
                <Link
                  className={styles.nav_link}
                  style={{
                    color:
                      location.pathname.indexOf('help-center') !== -1
                        ? '#2e5bff'
                        : '#ffffff',
                  }}
                  to={`/help-center${from ? `?from=${from}` : ''}`}
                >
                  帮助中心
                </Link>
              </li> */}
              {/* {isPainet() && (
                <li>
                  <a
                    className={styles.nav_link}
                    href="https://www.pybox.work/home"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    派享盒子
                  </a>
                </li>
              )} */}
              <li>
                <Button
                  type="default"
                  className={styles.nav_btn_login}
                  onClick={() => {
                    history.push(`/login${from ? `?from=${from}` : ''}`)
                  }}
                >
                  登录
                </Button>
              </li>
              <li>
                <Link
                  className={styles.nav_link}
                  style={{
                    color:
                      location.pathname.indexOf('/register') !== -1
                        ? '#2e5bff'
                        : '#ffffff',
                  }}
                  to={`/register${from ? `?from=${from}` : ''}`}
                >
                  注册
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.nav_right_flex}>
            <div className={styles.mobile_panel_login}>
              <Button
                className={styles.reg_btn}
                onClick={() => {
                  history.push(`/register${from ? `?from=${from}` : ''}`)
                }}
              >
                注册
              </Button>
              <Button
                className={styles.login_btn}
                onClick={() => {
                  history.push(`/login${from ? `?from=${from}` : ''}`)
                }}
              >
                登录
              </Button>
            </div>
            <div
              className={styles.btn_toggle}
              onClick={() => {
                setToggle((v) => !v)
              }}
            >
              <img
                alt="btn-toggle"
                src={
                  toggle
                    ? require('../../assets/welcome/close.png')
                    : require('../../assets/welcome/toggle.png')
                }
              />
            </div>
          </div>
          {/* <div
            className={
              toggle ? styles.mobile_panel__shown : styles.mobile_panel
            }
          >
            <ul className={styles.mobile_nav_list}>
              <li>
                <Link to={`/welcome${from ? `?from=${from}` : ''}`}>首页</Link>
              </li>
              <li>
                <Link to={`/vip-recruit${from ? `?from=${from}` : ''}`}>
                  大客户通道
                </Link>
              </li>
              <li>
                <Link to={`/help-center${from ? `?from=${from}` : ''}`}>
                  帮助中心
                </Link>
              </li>
            </ul>
          </div> */}
        </nav>
      </div>
      <NoticeModal
        title=""
        visible={registerModalVisible}
        closable={false}
        okText={'确定'}
        onOk={() => {
          setRegister(false)
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img src={Xiaozhouchao} width={200} alt="" />
          <div style={{ marginTop: '10px' }}>注册上机请扫码联系商务</div>
        </div>
      </NoticeModal>
    </div>
  )
}

export default Nav
