import React from 'react'

import Card from '../common/Card'
import { Title } from '@/views/wallet'
import CertificationContent from './content'
import { useGetUserInfoQuery } from './verifySlice'

export default () => {
  const { data: userInfo } = useGetUserInfoQuery()

  console.log('userInfo', userInfo)
  return (
    <>
      <Title>
        <div className="desc">实名认证</div>
      </Title>
      <Card>
        {userInfo?.needVerify && (
          <CertificationContent username={userInfo?.username} step={userInfo?.verifiedStep} uid={userInfo?.uid} />
        )}
        {!userInfo?.needVerify && (
          <div>
            <div>真实姓名：{userInfo?.realName}</div>
            <div>身份证号：{userInfo?.IDCardNumber.replace(/.{6}$/, '******')}</div>
            <div>手机号码：{userInfo?.phoneNumber}</div>
          </div>
        )}
      </Card>
    </>
  )
}
