import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import BeneficiaryInfo from './beneficiaryInfo'
import ModifyPassword from './modifyPassword'
import Certification from './certification'

export interface ManuItem {
  path: string
  title: string
  component: React.FunctionComponent<{}>
  authorities?: string[]
}

export const manuList: ManuItem[] = [
  {
    path: '/user/certification',
    title: '实名认证',
    component: Certification,
    // authorities: ['needReconcileUser'],
  },
  // {
  //   path: '/user/beneficiary',
  //   title: '收款信息',
  //   component: BeneficiaryInfo,
  //   authorities: ['needReconcileUser'],
  // },
  {
    path: '/user/password',
    title: '密码设置',
    component: ModifyPassword,
  },
]

export default () => {
  const { needReconcileUser } = useSelector((state: any) => state.user)
  const list = manuList.filter((item: ManuItem) => {
    return needReconcileUser || !item.authorities?.includes('needReconcileUser')
  })
  return (
    <Switch>
      {list.map((i: ManuItem) => (
        <Route path={i.path} key={i.path} component={i.component} />
      ))}
      <Redirect to={list[0].path}></Redirect>
    </Switch>
  )
}
